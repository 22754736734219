import Image from "next/image";
import { ReactElement } from "react";
import style from "./style.module.scss";

export default function Lawsuits(): ReactElement {
    return (
        <div className={style.wrapper}>
            <div className="md:w-10/12">
                <h2>Paraquat Lawsuit</h2>
                <p>
                    Paraquat is a weed-killing herbicide that's restricted to
                    commercial use only due to its high toxicity. Farmers and
                    agricultural laborers are most at risk for exposure, but
                    those who live near industrial farms may also be at risk of
                    paraquat exposure. This toxic herbicide has been linked to
                    many side effects, including Parkinson's disease. If you or
                    a loved one have been diagnosed with Parkinson's due to
                    paraquat exposure, you may be eligible to file a paraquat
                    lawsuit that can provide financial compensation to help
                    cover any medical expenses and lost wages.
                </p>
            </div>
            <div className="relative md:ml-10 w-[210px] h-[271px] mb-10 md:mb-0">
                <Image
                    src="/bestparaquatlawyers/assets/images/paraquatOpt.jpg"
                    alt="paraquat"
                    layout="fill"
                />
            </div>
        </div>
    );
}
